import {
  PERMISSIONS_LIST_REQUEST,
  PERMISSIONS_LIST_SUCCESS,
  PERMISSIONS_LIST_FAILURE,
  PERMISSIONS_ADD_REQUEST,
  PERMISSIONS_ADD_SUCCESS,
  PERMISSIONS_UPDATE_REQUEST,
  PERMISSIONS_UPDATE_SUCCESS,
  PERMISSIONS_REMOVE_REQUEST,
  PERMISSIONS_REMOVE_SUCCESS,
  PERMISSIONS_TREE_LIST_REQUEST,
  PERMISSIONS_TREE_LIST_SUCCESS,
  PERMISSIONS_TREE_LIST_FAILURE,
} from '@/constants/ActionsType';

export const permissionsListRequest = (data, callback) => {
  return { type: PERMISSIONS_LIST_REQUEST, payload: data, callback };
};
export const permissionsListSuccess = (data) => {
  return { type: PERMISSIONS_LIST_SUCCESS, payload: data };
};
export const permissionsListError = (data) => {
  return { type: PERMISSIONS_LIST_FAILURE, payload: data };
};
export const permissionsAddRequest = (data, callback) => {
  return { type: PERMISSIONS_ADD_REQUEST, payload: data, callback };
};
export const permissionsAddSuccess = (data) => {
  return { type: PERMISSIONS_ADD_SUCCESS, payload: data };
};
export const permissionsUpdateRequest = (data, callback) => {
  return { type: PERMISSIONS_UPDATE_REQUEST, payload: data, callback };
};
export const permissionsUpdateSuccess = (data) => {
  return { type: PERMISSIONS_UPDATE_SUCCESS, payload: data };
};
export const permissionsRemoveRequest = (data, callback) => {
  return { type: PERMISSIONS_REMOVE_REQUEST, payload: data, callback };
};
export const permissionsRemoveSuccess = (data) => {
  return { type: PERMISSIONS_REMOVE_SUCCESS, payload: data };
};
export const permissionsTreeRequest = () => {
  return { type: PERMISSIONS_TREE_LIST_REQUEST, payload: '' };
};
export const permissionsTreeListSuccess = (data) => {
  return { type: PERMISSIONS_TREE_LIST_SUCCESS, payload: data };
};
export const permissionsTreeListError = (data) => {
  return { type: PERMISSIONS_TREE_LIST_FAILURE, payload: data };
};
