import {
  ROLES_LIST_REQUEST,
  ROLES_LIST_SUCCESS,
  ROLES_LIST_FAILURE,
  ROLES_ADD_REQUEST,
  ROLES_ADD_SUCCESS,
  ROLES_UPDATE_REQUEST,
  ROLES_UPDATE_SUCCESS,
  ROLES_REMOVE_REQUEST,
  ROLES_REMOVE_SUCCESS,
} from '@/constants/ActionsType';

export const rolesListRequest = (data, callback) => {
  return { type: ROLES_LIST_REQUEST, payload: data, callback };
};
export const rolesListSuccess = (data) => {
  return { type: ROLES_LIST_SUCCESS, payload: data };
};
export const rolesListError = (data) => {
  return { type: ROLES_LIST_FAILURE, payload: data };
};
export const rolesAddRequest = (data, callback) => {
  return { type: ROLES_ADD_REQUEST, payload: data, callback };
};
export const rolesAddSuccess = (data) => {
  return { type: ROLES_ADD_SUCCESS, payload: data };
};
export const rolesUpdateRequest = (data, callback) => {
  return { type: ROLES_UPDATE_REQUEST, payload: data, callback };
};
export const rolesUpdateSuccess = (data) => {
  return { type: ROLES_UPDATE_SUCCESS, payload: data };
};
export const rolesRemoveRequest = (data) => {
  return { type: ROLES_REMOVE_REQUEST, payload: data };
};
export const rolesRemoveSuccess = (data) => {
  return { type: ROLES_REMOVE_SUCCESS, payload: data };
};
