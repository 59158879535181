import { put, call, all, takeLatest } from 'redux-saga/effects';
// import { message } from 'antd';
import message from "antd/lib/message";
import { Toast } from '@/utils';
import Api from '@/api';
import {
  ROLES_LIST_REQUEST,
  ROLES_ADD_REQUEST,
  ROLES_UPDATE_REQUEST,
  ROLES_REMOVE_REQUEST,
} from '@/constants/ActionsType';
import {
  rolesListSuccess,
  rolesListError,
  rolesAddSuccess,
  rolesUpdateSuccess,
  rolesRemoveSuccess,
} from '../actions/role';

function rolesFetch(data) {
  return Api.rolesList(data);
}
function rolesAdd(data) {
  return Api.rolesAdd(data);
}
function rolesRemove(id) {
  return Api.rolesDel(id);
}
function rolesUpdate({ id, data }) {
  return Api.rolesUpdate(id, data);
}
function* rolesList(action) {
  const { callback } = action;
  try {
    const res = yield call(rolesFetch, action.payload);

    if (res.code === 10000) {
      yield put(rolesListSuccess(res.data));
      if (callback) {
        callback();
      }
    } else {
      yield put(rolesListError(res.message));
    }
  } catch (e) {
    yield put(rolesListError('获取角色信息数据出错'));
  }
}
function* rolesRemoveRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(rolesRemove, payload);
    if (res.code === 10000) {
      yield put(rolesRemoveSuccess({ id: payload }));
      message.success(`删除角色操作成功！`);
      if (callback) {
        callback();
      }
    } else {
      Toast(`删除角色操作失败!-${res.message}`);
    }
  } catch (e) {
    Toast(`删除角色操作失败！`);
  }
}
function* rolesUpdateRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(rolesUpdate, payload);
    if (res.code === 10000) {
      message.success(`编辑角色操作成功！`);
      yield put(
        rolesUpdateSuccess({
          ...payload.data,
          optLock: res.data,
          id: payload.id,
        }),
      );
      if (callback) {
        callback();
      }
    } else {
      Toast(`编辑角色操作失败，${res.message}`);
    }
  } catch (e) {
    Toast(`编辑角色操作失败！`);
  }
}
function* rolesAddRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(rolesAdd, payload);

    if (res.code === 10000) {
      yield put(
        rolesAddSuccess({
          id: res.data,
          ...payload,
          optLock: 0,
        }),
      );
      message.success(`添加角色操作成功！`);
      if (callback) {
        callback();
      }
    } else {
      Toast(`添加角色操作失败，${res.message}`);
    }
  } catch (e) {
    Toast(`添加角色操作失败！`);
  }
}

export default function* root() {
  yield all([
    takeLatest(ROLES_LIST_REQUEST, rolesList),
    takeLatest(ROLES_ADD_REQUEST, rolesAddRequest),
    takeLatest(ROLES_UPDATE_REQUEST, rolesUpdateRequest),
    takeLatest(ROLES_REMOVE_REQUEST, rolesRemoveRequest),
  ]);
}
