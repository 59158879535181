export const API_ROOT = '/mgmt';
export const API_AUTH = '/auth';
export const UPLOAD_ROOT = '/mgmt/upload/file';
export const UPLOADIMG_ROOT = '/mgmt/lucky/uploadimg';
export const Api = {
  Code: '/vCode',
  Login: '/login',
  Logout: '/logout',
  MenusBuild: '/sys/menus/build',
  Menus: '/sys/menus',
  MenusTree: '/sys/menus/tree',
  Account: '/sys/users',
  AccountInfo: '/sys/users/info',
  Roles: '/sys/roles',
  RolesTree: 'sys/roles/tree',
  Permissions: '/sys/permissions',
  PermissionsTree: '/sys/permissions/tree',
  Users: '/sys/users',
  Password: '/sys/users/updatePass',
  Resources: '/resources',
};
