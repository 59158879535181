import axios from 'axios';
import { Toast } from '@/utils';
import { store } from '@/redux/store';
import { updateHttpStatus } from '@/redux/actions/network';
import { loginFailure, updateToken } from '@/redux/actions/user';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';
// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    if (store.getState().userReducer.token) {
      config.headers['SSMgmtToken'] = `Bearer ${store.getState().userReducer.token}`;
    }
    NProgress.start();
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

// http response 拦截器
axios.interceptors.response.use(
  (response) => {
    const token = response.headers['SSMgmtToken'] || response.headers['ssmgmttoken'];
    if (token) {
      store.dispatch(updateToken(token));
    }
    NProgress.done();
    return response;
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 403:
          store.dispatch(updateHttpStatus(403));
          break;
        case 500:
          store.dispatch(updateHttpStatus(500));
          break;
        default:
          break;
      }
      return Promise.reject(error);
    }
  },
);
function checkStatus(response) {
  // loading
  // 如果http状态码正常，则直接返回数据
  if (response && (response.status === 200 || response.status === 304 || response.status === 400)) {
    return response;
    // 如果不需要除了data之外的数据，可以直接 return response.data
  }
  // 异常状态下，把错误信息返回去
  return {
    status: -404,
    msg: '网络异常!',
    data: {},
  };
}

function checkCode(res) {
  // 如果code异常(这里已经包括网络错误，服务器错误，后端抛出的错误)，可以弹出一个错误提示，告诉用户
  if (res.status === -404) {
    Toast(res.msg);
  }
  if (res.config.responseType === 'blob') {
    return res
  }
  return { ...res.data };
}

const request = (url, options) => {
  const newOptions = { ...options, url };

  return new Promise((resolve, reject) => {
    axios(newOptions)
      .then((response) => {
        return checkStatus(response);
      })
      .then((res) => {
        resolve(checkCode(res));
      })
      .catch((error) => {
        NProgress.done();
        if (error.response && error.response.status === 401) {
          if (!window.location.href.includes('/login')) {
            store.dispatch(loginFailure(401, '登录超时,请重新登录！', window.location.href));
          }
        } else if (error.response && error.response.status === 400) {
          resolve({ ...error.response.data });
        } else if (error.response && error.response.status === 404) {
          Toast(error.response.data.message);
        } else {
          Toast('服务器异常，稍后重试！');
        }
        reject({ error, code: error.response.status });
      });
  });
};
export default request;
