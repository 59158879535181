import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import moment from 'moment';
import PrivateRoute from './PrivateRoute';
import ServerErr from '../routes/Exception/500';
import NoAuth from '../routes/Exception/403';
import { getRouterData } from '../common/router';
import { ROUTE_BASE } from '../utils/config';
import zhCN from 'antd/lib/locale/zh_CN';
import enUS from 'antd/lib/locale/en_US';
import 'moment/locale/zh-cn';

const locales = {
  'en-US': require('@/locales/en_US.json'),
  'zh-CN': require('@/locales/zh_CN.json'),
};
moment.locale('zh-cn');
const routerData = getRouterData();
const LoginLayout = routerData['/login'].component;
const BasicLayout = routerData['/'].component;
function Layout() {
  return (
    <Switch>
      <Route path="/" component={BasicLayout} />
    </Switch>
  );
}

const loadLocales = (lang = 'zh-CN') => {
  intl
    .init({
      currentLocale: lang, // 设置初始语言
      locales,
    })
    .then(() => {});
};

const routes = (props) => {
  const locale = props.language === 'zh_CN' ? zhCN : enUS;
  loadLocales(props.language === 'zh_CN' ? 'zh-CN' : 'en-US');
  return (
    <ConfigProvider locale={locale}>
      <BrowserRouter basename={ROUTE_BASE}>
        <Switch>
          <Route exact path="/login" component={LoginLayout} />
          <Route exact path="/exception/403" component={NoAuth} />
          <Route exact path="/exception/500" component={ServerErr} />
          <PrivateRoute path="/" component={Layout} />
        </Switch>
      </BrowserRouter>
    </ConfigProvider>
  );
};
const mapStateToProps = (state) => ({
  language: state.userReducer.language,
});
export default connect(mapStateToProps)(routes);
