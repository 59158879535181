import crypto from 'crypto-browserify';
import moment from 'moment';
import React from 'react';
import { Tree, message } from 'antd';
import { parse, stringify } from 'qs';

const TreeNode = Tree.TreeNode;
// 使用随机值加盐，MD5加密
export function cryptPwd(password) {
  //   const salt = Math.random().toString().slice(2, 5);
  // 密码“加盐”
  //   const saltPassword = password + ':' + salt;
  const saltPassword = password;

  // 加盐密码的md5值
  const md5 = crypto.createHash('md5');
  const result = md5.update(saltPassword).digest('hex');
  return result;
}
// 获取url参数
export function getUrlPara(url, key) {
  const queryStr = url.slice(1);
  const reg = new RegExp(`(^|&)${key}=([^&]*)(&|$)`, 'i');
  const matchRes = queryStr.match(reg);
  if (matchRes && matchRes[2]) {
    return decodeURIComponent(matchRes[2]);
  } else {
    return null;
  }
}
export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}
export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(1);
    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(1);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - day * oneDay;

    return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
  }

  if (type === 'month') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = moment(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();

    return [
      moment(`${year}-${fixedZero(month + 1)}-01 00:00:01`),
      moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:01`).valueOf() - 1000),
    ];
  }

  if (type === 'year') {
    const year = now.getFullYear();

    return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
  }
}

function getRelation(str1, str2) {
  if (str1 === str2) {
    console.warn('Two path are equal!'); // eslint-disable-line
  }
  const arr1 = str1.split('/');
  const arr2 = str2.split('/');
  if (arr2.every((item, index) => item === arr1[index])) {
    return 1;
  } else if (arr1.every((item, index) => item === arr2[index])) {
    return 2;
  }
  return 3;
}

function getRenderArr(routes) {
  let renderArr = [];
  renderArr.push(routes[0]);
  for (let i = 1; i < routes.length; i += 1) {
    let isAdd = false;
    // 是否包含
    isAdd = renderArr.every((item) => getRelation(item, routes[i]) === 3);
    // 去重
    renderArr = renderArr.filter((item) => getRelation(item, routes[i]) !== 1);
    if (isAdd) {
      renderArr.push(routes[i]);
    }
  }
  return renderArr;
}

/**
 * Get router routing configuration
 * { path:{name,...param}}=>Array<{name,path ...param}>
 * @param {string} path
 * @param {routerData} routerData
 */
export function getRoutes(path, routerData) {
  let routes = Object.keys(routerData).filter(
    (routePath) => routePath.indexOf(path) === 0 && routePath !== path,
  );
  // Replace path to '' eg. path='user' /user/name => name
  routes = routes.map((item) => item.replace(path, ''));
  // Get the route to be rendered to remove the deep rendering
  const renderArr = getRenderArr(routes);
  // Conversion and stitching parameters
  const renderRoutes = renderArr.map((item) => {
    const exact = !routes.some((route) => route !== item && getRelation(route, item) === 1);
    return {
      exact,
      ...routerData[`${path}${item}`],
      key: `${path}${item}`,
      path: `${path}${item}`,
    };
  });
  return renderRoutes;
}

export function getPageQuery() {
  return parse(window.location.href.split('?')[1]);
}

export function getQueryPath(path = '', query = {}) {
  const search = stringify(query);
  if (search.length) {
    return `${path}?${search}`;
  }
  return path;
}
// 递归查找节点id为指定id的节点
export function findNodeById(arr, id) {
  return arr.reduce((acc, item) => {
    if (item.key === id) {
      return acc.concat(item);
    }
    if (item.children && item.children.length) {
      return acc.concat(findNodeById(item.children, id));
    }
    return acc;
  }, []);
}
// 递归获取某个节点的id集合，包括所有子节点
export function getNodeIds(arr) {
  return arr.reduce((acc, item) => {
    if (item.children && item.children.length) {
      return acc.concat(item.key).concat(getNodeIds(item.children));
    }
    return acc.concat(item.key);
  }, []);
}
// 将给定的数据转换成Tree,TreeSelect指定的数据结构
export function composeTreeSelectData(data) {
  if (data && data.length) {
    return data.map((item) => {
      let obj = {};
      obj.title = item.name;
      obj.key = item.id;
      obj.value = item.id;
      if (item.childList && item.childList.length)
        obj.children = composeTreeSelectData(item.childList);
      return obj;
    });
  } else {
    return [];
  }
}
// 将给定的数据转换成Tree,TreeSelect指定的数据结构
export function composeTreeData(data) {
  if (data && data.length) {
    return data.map((item) => {
      if (item.childList && item.childList.length) {
        return (
          <TreeNode key={item.id} title={item.name}>
            {composeTreeData(item.childList)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.id} title={item.name} />;
    });
  } else {
    return [];
  }
}

export const regenerateTreeData = (data) => {
  return data.map((item) => {
    let obj = {
      value: item.name,
      title: item.name,
      key: item.id,
    };
    if (item.children && item.children.length) {
      obj.children = regenerateTreeData(item.children);
    }
    return obj;
  });
};

export function getDeviceTime(seconds) {
  const day = Math.floor(seconds / (24 * 3600));
  const hours = Math.floor((seconds % (24 * 3600)) / 3600);
  const minutes = Math.ceil((seconds - day * 24 * 3600 - hours * 3600) / 60);
  return `${day}天${hours}小时${minutes}分钟`;
}
export const getDictionaryTypeList = (state, type) => {
  const [deviceTypeObj] = state.userReducer.dictionary.filter((item) => item[type] !== undefined);
  if (deviceTypeObj) {
    return deviceTypeObj[type];
  }
  return [];
};

export const Toast = (msg) => {
  if (Toast.isShow) return;
  Toast.isShow = true;
  message.error(msg).then(() => (Toast.isShow = false));
};


export const res2BlobDownload = (res) => {
  try {
    const fileName = res.headers['content-disposition'].match(
      /filename=(.*)/
    )[1].replace(/\"/g, "")
    const blob = new Blob([res.data], { type: 'application/octet-stream' })
    let url = window.URL.createObjectURL(blob);
    let eleLink = document.createElement('a');
    eleLink.href = url;
    eleLink.style.display = 'none'
    eleLink.setAttribute('download', decodeURI(fileName))
    document.body.appendChild(eleLink);
    eleLink.click();
    document.body.removeChild(eleLink)
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('download function error!', error);
  }
}