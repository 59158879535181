import React from 'react';
import { Menu } from 'antd';
import IconMap from '@/utils/icon';
import { Link } from 'react-router-dom';

const SubMenu = Menu.SubMenu;

const RecursiveMenus = (menuTree) => {
  return menuTree.map((item) => {
    if (item.children && item.children.length > 0) {
      return (
        <SubMenu
          key={item.path}
          title={
            <span>
              {item.meta && item.meta.icon && IconMap[item.meta.icon]}
              <span>{item.name}</span>
            </span>
          }
        >
          {RecursiveMenus(item.children)}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={item.path}>
        <Link to={item.path}>
          {item.meta && item.meta.icon && IconMap[item.meta.icon]}
          <span>{item.name}</span>
        </Link>
      </Menu.Item>
    );
  });
};

export const composeTreeData = (title, data) => {
  return data.map((item) => {
    let obj = { title: item[title], key: item.id, value: item.id };
    if (item.children && item.children.length > 0) {
      obj.children = composeTreeData(title, item.children);
    }
    return obj;
  });
};
export const composeCompanyTreeData = (title, data, deepIndex) => {
  return data.map((item) => {
    let obj = { title: item[title], key: item.id, value: item.id };
    if (item.children && item.children.length > 0) {
      if (deepIndex < 3) {
        obj.children = composeCompanyTreeData(title, item.children, deepIndex + 1);
      } else {
        obj.children = [];
      }
    }
    return obj;
  });
};
export const composeCascaderData = (label, data) => {
  return data.map((item) => {
    let obj = { label: item[label], key: item.id, value: item.id };
    if (item.children && item.children.length > 0) {
      obj.children = composeCascaderData(label, item.children);
    }
    return obj;
  });
};

export const getFirstMenu = (path, menu) => {
  let firstMenu = menu[0];

  if (firstMenu && firstMenu.children) {
    return getFirstMenu(`${path}${firstMenu.path}/`, firstMenu.children);
  }
  if (firstMenu) {
    return path + firstMenu.path;
  }
  return path;
};
export default RecursiveMenus;
