import {
  PERMISSIONS_LIST_REQUEST,
  PERMISSIONS_LIST_SUCCESS,
  PERMISSIONS_LIST_FAILURE,
  PERMISSIONS_ADD_SUCCESS,
  PERMISSIONS_UPDATE_SUCCESS,
  PERMISSIONS_REMOVE_SUCCESS,
  PERMISSIONS_TREE_LIST_SUCCESS,
  PERMISSIONS_TREE_LIST_FAILURE,
} from '@/constants/ActionsType';
import { Toast } from '@/utils';

const initState = {
  list: [],
  loading: false,
  tree: [],
};

function permissionReducer(state = initState, action) {
  switch (action.type) {
    case PERMISSIONS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PERMISSIONS_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case PERMISSIONS_LIST_FAILURE:
      Toast(action.payload);
      return {
        ...state,
        loading: false,
      };
    case PERMISSIONS_REMOVE_SUCCESS:
      return {
        ...state,
        list: state.list.filter((item) => item.id !== action.payload.id),
      };
    case PERMISSIONS_ADD_SUCCESS:
      return {
        ...state,
        list: state.list.concat(action.payload),
      };
    case PERMISSIONS_UPDATE_SUCCESS:
      return {
        ...state,
        list: state.list.map((item) => {
          if (item.id === action.payload.id) {
            Object.keys(action.payload).forEach((key) => {
              item[key] = action.payload[key];
            });
            return item;
          }
          return item;
        }),
      };
    case PERMISSIONS_TREE_LIST_SUCCESS:
      return {
        ...state,
        tree: action.payload,
      };
    case PERMISSIONS_TREE_LIST_FAILURE:
      Toast(action.payload);
      return {
        ...state,
      };
    default:
      return state;
  }
}

export default permissionReducer;
