import {
  SettingOutlined,
  UserOutlined,
  DashboardOutlined,
  ToolOutlined,
  UnorderedListOutlined,
  TrophyOutlined,
  TeamOutlined,
  ShoppingCartOutlined,
  ReadOutlined,
  AppstoreOutlined,
  GiftOutlined,
  CommentOutlined,
  ClusterOutlined,
  AndroidOutlined,
  AppleOutlined,
  AlertOutlined,
  DropboxOutlined,
  WomanOutlined,
  ApartmentOutlined,
  HistoryOutlined,
  ContactsTwoTone,
  FireTwoTone,
  VideoCameraTwoTone,
  PushpinTwoTone,
  PieChartTwoTone,
  FundTwoTone
} from '@ant-design/icons';
const iconList = {
  SettingOutlined: <SettingOutlined />,
  UserOutlined: <UserOutlined />,
  DashboardOutlined: <DashboardOutlined />,
  ToolOutlined: <ToolOutlined />,
  UnorderedListOutlined: <UnorderedListOutlined />,
  TrophyOutlined: <TrophyOutlined />,
  TeamOutlined: <TeamOutlined />,
  ShoppingCartOutlined: <ShoppingCartOutlined />,
  ReadOutlined: <ReadOutlined />,
  AppstoreOutlined: <AppstoreOutlined />,
  GiftOutlined: <GiftOutlined />,
  CommentOutlined: <CommentOutlined />,
  ClusterOutlined: <ClusterOutlined />,
  AndroidOutlined: <AndroidOutlined />,
  AppleOutlined: <AppleOutlined />,
  AlertOutlined: <AlertOutlined />,
  DropboxOutlined:<DropboxOutlined />,
  WomanOutlined:<WomanOutlined />,
  ApartmentOutlined:<ApartmentOutlined />,
  HistoryOutlined:<HistoryOutlined />,
  ContactsTwoTone:<ContactsTwoTone />,
  FireTwoTone:<FireTwoTone />,
  VideoCameraTwoTone:<VideoCameraTwoTone />,
  PushpinTwoTone:<PushpinTwoTone />,
  PieChartTwoTone:<PieChartTwoTone />,
  FundTwoTone:<FundTwoTone />
};

export default iconList;
