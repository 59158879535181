export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const UPDATE_LOGO = 'UPDATE_LOGO';
export const UPDATE_MENU_REQUEST = 'UPDATE_MENU_REQUEST';
export const UPDATE_MENU_SUCCESS = 'UPDATE_MENU_SUCCESS';
export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const LOGOUT_AFTER_PASSWORD = 'LOGOUT_AFTER_PASSWORD';
export const SET_LANGUAGE = 'SET_LANGUAGE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

// 设置menu菜单选中
export const DEFAULTITEM = 'DEFAULTITEM';
export const OPENKEYS = 'OPENKEYS';
export const MENUFOLD = 'MENUFOLD';
export const MENUCLEAR = 'MENUCLEAR';

export const UPDATE_HTTP_STATUS = 'UPDATE_HTTP_STATUS';
export const RESET_HTTP_STATUS = 'RESET_HTTP_STATUS';

export const CHANGE_LOCALE = 'CHANGE_LOCALE';

export const RESOURCES_LIST_REQUEST = 'RESOURCES_LIST_REQUEST';
export const RESOURCES_LIST_SUCCESS = 'RESOURCES_LIST_SUCCESS';
export const RESOURCES_LIST_FAILURE = 'RESOURCES_LIST_FAILURE';
export const RESOURCES_ADD_REQUEST = 'RESOURCES_ADD_REQUEST';
export const RESOURCES_UPDATE_REQUEST = 'RESOURCES_UPDATE_REQUEST';
export const RESOURCES_REMOVE_REQUEST = 'RESOURCES_REMOVE_REQUEST';
export const RESOURCES_ADD_SUCCESS = 'RESOURCES_ADD_SUCCESS';
export const RESOURCES_UPDATE_SUCCESS = 'RESOURCES_UPDATE_SUCCESS';
export const RESOURCES_REMOVE_SUCCESS = 'RESOURCES_REMOVE_SUCCESS';

export const ROLES_LIST_REQUEST = 'ROLES_LIST_REQUEST';
export const ROLES_LIST_SUCCESS = 'ROLES_LIST_SUCCESS';
export const ROLES_LIST_FAILURE = 'ROLES_LIST_FAILURE';
export const ROLES_ADD_REQUEST = 'ROLES_ADD_REQUEST';
export const ROLES_UPDATE_REQUEST = 'ROLES_UPDATE_REQUEST';
export const ROLES_REMOVE_REQUEST = 'ROLES_REMOVE_REQUEST';
export const ROLES_ADD_SUCCESS = 'ROLES_ADD_SUCCESS';
export const ROLES_UPDATE_SUCCESS = 'ROLES_UPDATE_SUCCESS';
export const ROLES_REMOVE_SUCCESS = 'ROLES_REMOVE_SUCCESS';

export const PERMISSIONS_LIST_REQUEST = 'PERMISSIONS_LIST_REQUEST';
export const PERMISSIONS_LIST_SUCCESS = 'PERMISSIONS_LIST_SUCCESS';
export const PERMISSIONS_LIST_FAILURE = 'PERMISSIONS_LIST_FAILURE';
export const PERMISSIONS_ADD_REQUEST = 'PERMISSIONS_ADD_REQUEST';
export const PERMISSIONS_UPDATE_REQUEST = 'PERMISSIONS_UPDATE_REQUEST';
export const PERMISSIONS_REMOVE_REQUEST = 'PERMISSIONS_REMOVE_REQUEST';
export const PERMISSIONS_ADD_SUCCESS = 'PERMISSIONS_ADD_SUCCESS';
export const PERMISSIONS_UPDATE_SUCCESS = 'PERMISSIONS_UPDATE_SUCCESS';
export const PERMISSIONS_REMOVE_SUCCESS = 'PERMISSIONS_REMOVE_SUCCESS';
export const PERMISSIONS_TREE_LIST_REQUEST = 'PERMISSIONS_TREE_LIST_REQUEST';
export const PERMISSIONS_TREE_LIST_SUCCESS = 'PERMISSIONS_TREE_LIST_SUCCESS';
export const PERMISSIONS_TREE_LIST_FAILURE = 'PERMISSIONS_TREE_LIST_FAILURE';

export const MENUS_LIST_REQUEST = 'MENUS_LIST_REQUEST';
export const MENUS_LIST_SUCCESS = 'MENUS_LIST_SUCCESS';
export const MENUS_LIST_FAILURE = 'MENUS_LIST_FAILURE';
export const MENUS_ADD_REQUEST = 'MENUS_ADD_REQUEST';
export const MENUS_UPDATE_REQUEST = 'MENUS_UPDATE_REQUEST';
export const MENUS_REMOVE_REQUEST = 'MENUS_REMOVE_REQUEST';
export const MENUS_ADD_SUCCESS = 'MENUS_ADD_SUCCESS';
export const MENUS_UPDATE_SUCCESS = 'MENUS_UPDATE_SUCCESS';
export const MENUS_REMOVE_SUCCESS = 'MENUS_TREE_LIST_REQUEST';
export const MENUS_TREE_LIST_REQUEST = 'MENUS_TREE_LIST_REQUEST';
export const MENUS_TREE_LIST_SUCCESS = 'MENUS_TREE_LIST_SUCCESS';
export const MENUS_TREE_LIST_FAILURE = 'MENUS_TREE_LIST_FAILURE';
