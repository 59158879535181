import { DEFAULTITEM, OPENKEYS, MENUFOLD, MENUCLEAR } from '../../constants/ActionsType';
const initState = {
  currentItem: '',
  openKeys: [''],
  menuFold: false,
};

function slideMenuReducer(state = initState, action) {
  switch (action.type) {
    case DEFAULTITEM:
      return {
        ...state,
        currentItem: action.currentItem,
      };
    case OPENKEYS:
      return {
        ...state,
        openKeys: action.openKeys,
      };
    case MENUFOLD:
      return {
        ...state,
        menuFold: action.menuFold,
      };
    case MENUCLEAR:
      return initState;
    default:
      return state;
  }
}

export default slideMenuReducer;
