import React, { useEffect } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import Exception from '@/components/Exception';
import { resetHttpStatus } from '@/redux/actions/network';
import styles from './index.module.less';

function Error(props) {
  const { title, imgSrc, type, history, resetHttpStatus } = props;
  const handleReturnClick = () => {
    history.push('/');
  };
  useEffect(() => {
    resetHttpStatus();
  }, []);

  const actions = (
    <Button type="primary" onClick={handleReturnClick}>
      回到首页
    </Button>
  );
  return (
    <div className={styles.nomatch}>
      <Helmet title={title} />
      <Exception type={type} img={imgSrc} actions={actions} />
    </div>
  );
}

const mapDispatchToProp = (dispatch) => ({
  resetHttpStatus: () => {
    dispatch(resetHttpStatus());
  },
});
export default connect(null, mapDispatchToProp)(withRouter(Error));
