import { put, call, all, takeLatest } from 'redux-saga/effects';
// import { message } from 'antd';
import message from "antd/lib/message";
import intl from 'react-intl-universal';

import Api from '@/api';
import {
  RESOURCES_LIST_REQUEST,
  RESOURCES_ADD_REQUEST,
  RESOURCES_UPDATE_REQUEST,
  RESOURCES_REMOVE_REQUEST,
} from '@/constants/ActionsType';
import {
  resourcesListSuccess,
  resourcesListError,
  resourcesAddSuccess,
  resourcesUpdateSuccess,
} from '../actions/resources';

function resourcesFetch(data) {
  return Api.resourcesList(data);
}
function resourcesAdd(data) {
  return Api.resourcesAdd(data);
}
function resourcesRemove(id) {
  return Api.resourcesDel(id);
}
function resourcesUpdate(data) {
  return Api.resourcesEdit(data);
}
function* resourcesList(action) {
  const { callback } = action;
  try {
    const res = yield call(resourcesFetch, action.payload);

    if (res.code === 10000) {
      yield put(resourcesListSuccess(res.data));
      if (callback) {
        callback();
      }
    } else {
      yield put(resourcesListError(res.message));
    }
  } catch (e) {
    yield put(resourcesListError(intl.get('ResourceError')));
  }
}
function* resourcesRemoveRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(resourcesRemove, payload.id);
    if (res.code === 10000) {
      message.success(
        intl.get('ActionSuccess', { name: intl.get('Resource'), action: intl.get('Delete') }),
      );
      if (callback) {
        callback();
      }
    } else {
      message.error(
        intl.get('ActionError', { name: intl.get('Resource'), action: intl.get('Delete') }),
      );
    }
  } catch (e) {
    message.error(
      intl.get('ActionError', { name: intl.get('Resource'), action: intl.get('Delete') }),
    );
  }
}
function* resourcesUpdateRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(resourcesUpdate, payload);
    if (res.code === 10000) {
      message.success(
        intl.get('ActionSuccess', { name: intl.get('Resource'), action: intl.get('Edit') }),
      );
      yield put(
        resourcesUpdateSuccess({
          ...payload.data,
          id: payload.id,
        }),
      );
      if (callback) {
        callback();
      }
    } else {
      message.error(
        intl.get('ActionError', { name: intl.get('Resource'), action: intl.get('Edit') }),
      );
    }
  } catch (e) {
    message.error(
      intl.get('ActionError', { name: intl.get('Resource'), action: intl.get('Edit') }),
    );
  }
}
function* resourcesAddRequest(action) {
  const { payload, callback } = action;
  try {
    const res = yield call(resourcesAdd, payload);

    if (res.code === 10000) {
      yield put(
        resourcesAddSuccess({
          id: res.data,
          ...payload,
          optLock: 0,
        }),
      );
      message.success(
        intl.get('ActionSuccess', { name: intl.get('Resource'), action: intl.get('Add') }),
      );
      if (callback) {
        callback();
      }
    } else {
      message.error(
        intl.get('ActionError', { name: intl.get('Resource'), action: intl.get('Add') }),
      );
    }
  } catch (e) {
    message.error(intl.get('ActionError', { name: intl.get('Resource'), action: intl.get('Add') }));
  }
}

export default function* root() {
  yield all([
    takeLatest(RESOURCES_LIST_REQUEST, resourcesList),
    takeLatest(RESOURCES_ADD_REQUEST, resourcesAddRequest),
    takeLatest(RESOURCES_UPDATE_REQUEST, resourcesUpdateRequest),
    takeLatest(RESOURCES_REMOVE_REQUEST, resourcesRemoveRequest),
  ]);
}
