import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGIN_FAILURE,
  UPDATE_TOKEN,
  UPDATE_LOGO,
  UPDATE_AVATAR,
  UPDATE_MENU_SUCCESS,
  UPDATE_MENU_REQUEST,
  LOGOUT_AFTER_PASSWORD,
  SET_LANGUAGE,
} from '@/constants/ActionsType';

export const loginRequest = (data, callback) => {
  return {
    type: LOGIN_REQUEST,
    payload: data,
    callback,
  };
};

export const logoutRequest = () => {
  return { type: LOGOUT_REQUEST };
};

export const logoutAfterPasswrodRequest = () => {
  return { type: LOGOUT_AFTER_PASSWORD };
};
export const updateToken = (data) => {
  return { type: UPDATE_TOKEN, payload: data };
};
export const updateAvatarRequest = (data) => {
  return { type: UPDATE_AVATAR, payload: data };
};
export const updateMenuSuccess = (data) => {
  return { type: UPDATE_MENU_SUCCESS, payload: data };
};
export const updateMenuRequest = () => {
  return { type: UPDATE_MENU_REQUEST };
};
export const updateLogo = (data) => {
  return { type: UPDATE_LOGO, payload: data };
};
export const loginSuccess = (data) => {
  return { type: LOGIN_SUCCESS, payload: data };
};

export const logoutSuccess = () => {
  return { type: LOGOUT_SUCCESS };
};

export function loginFailure(status, msg, redirect) {
  return { type: LOGIN_FAILURE, status, msg, redirect };
}
export function setLanguageRequest(data) {
  return { type: SET_LANGUAGE, payload: data };
}
