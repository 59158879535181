// saga 模块化引入
import { fork, all } from 'redux-saga/effects';

// 异步逻辑
import user from './user';
import resources from './resources';
import menu from './menu';
import role from './role';
import permission from './permission';

// 单一进入点，一次启动所有 Saga
export default function* rootSaga() {
  yield all([fork(user), fork(menu), fork(resources),fork(role), fork(permission)]);
}
