import request from "../http/index";
import { API_ROOT, API_AUTH, Api } from "../constants/ApiConfig";

const API = {
  Code() {
    return request(`${API_AUTH}${Api.Code}/1615777562209`, {
      method: "get"
    });
  },
  Login(data) {
    return request(`${API_AUTH}${Api.Login}`, {
      method: "post",
      data
    });
  },
  Logout() {
    return request(`${API_ROOT}/sys/users/logout`, {
      method: "post"
    });
  },
  updateAvatar(data) {
    return request(`${API_ROOT}/sys/users/updateAvatar`, {
      method: "post",
      data
    });
  },
  menusList(data) {
    return request(`${API_ROOT}${Api.Menus}/list`, {
      method: "get",
      params: data
    });
  },
  menusTree(data) {
    return request(`${API_ROOT}${Api.MenusTree}`, {
      method: "get",
      params: data
    });
  },
  menusBuild(data) {
    return request(`${API_ROOT}${Api.MenusBuild}`, {
      method: "get",
      params: data
    });
  },
  menusAdd(data) {
    return request(`${API_ROOT}${Api.Menus}`, {
      method: "post",
      data
    });
  },
  menusUpdate(id, data) {
    return request(`${API_ROOT}${Api.Menus}/${id}`, {
      method: "put",
      data
    });
  },
  menusDel(id) {
    return request(`${API_ROOT}${Api.Menus}/${id}`, {
      method: "delete"
    });
  },
  dictionary() {
    return request(`${API_ROOT}/sys/dict/tree`, {
      method: "get"
    });
  },
  accountsList(data) {
    return request(`${API_ROOT}${Api.Account}`, {
      method: "get",
      params: data
    });
  },
  accountsAdd(data) {
    return request(`${API_ROOT}${Api.Account}`, {
      method: "post",
      data
    });
  },
  accountsUpdate(id, data) {
    return request(`${API_ROOT}${Api.Account}/${id}`, {
      method: "put",
      data
    });
  },
  accountsDel(id) {
    return request(`${API_ROOT}${Api.Account}/${id}`, {
      method: "delete"
    });
  },
  accountsInfo() {
    return request(`${API_ROOT}/sys/users/info`, {
      method: "get"
    });
  },
  accountsEnable(id) {
    return request(`${API_ROOT}/sys/users/${id}/enable`, {
      method: "post"
    });
  },
  accountsDisable(id) {
    return request(`${API_ROOT}/sys/users/${id}/disable`, {
      method: "post"
    });
  },
  permissionsList(data) {
    return request(`${API_ROOT}${Api.Permissions}`, {
      method: "get",
      params: data
    });
  },
  permissionsTree() {
    return request(`${API_ROOT}${Api.PermissionsTree}`, {
      method: "get"
    });
  },
  permissionsAdd(data) {
    return request(`${API_ROOT}${Api.Permissions}`, {
      method: "post",
      data
    });
  },
  permissionsUpdate(id, data) {
    return request(`${API_ROOT}${Api.Permissions}/${id}`, {
      method: "put",
      data
    });
  },
  permissionsDel(id) {
    return request(`${API_ROOT}${Api.Permissions}/${id}`, {
      method: "delete"
    });
  },
  rolesList(data) {
    return request(`${API_ROOT}${Api.Roles}`, {
      method: "get",
      params: data
    });
  },
  rolesTree(data) {
    return request(`${API_ROOT}${Api.RolesTree}`, {
      method: "get",
      params: data
    });
  },
  rolesAllList(data) {
    return request(`${API_ROOT}${Api.Roles}`, {
      method: "get",
      params: data
    });
  },
  rolesAdd(data) {
    return request(`${API_ROOT}${Api.Roles}`, {
      method: "post",
      data
    });
  },
  rolesUpdate(id, data) {
    return request(`${API_ROOT}${Api.Roles}/${id}`, {
      method: "put",
      data
    });
  },
  rolesMenuUpdate(id, data) {
    return request(`${API_ROOT}${Api.Roles}/${id}/menus/${data}`, {
      method: "post"
    });
  },
  rolesPermissionUpdate(id, data) {
    return request(`${API_ROOT}${Api.Roles}/${id}/permission`, {
      method: "put",
      data
    });
  },
  rolesDel(id) {
    return request(`${API_ROOT}${Api.Roles}/${id}`, {
      method: "delete"
    });
  },
  setPassword(data) {
    return request(`${API_ROOT}${Api.Password}`, {
      method: "post",
      data
    });
  },
  resetPassword(data) {
    return request(`${API_ROOT}/sys/users/resetPass`, {
      method: "post",
      data
    });
  },
  resourcesList(data) {
    return request(`${API_ROOT}${Api.Resources}`, {
      method: "get",
      params: { ...data, from: 1 }
    });
  },

  resourcesEdit(data) {
    return request(`/mgmt/upload/file/update`, {
      method: "post",
      data
    });
  },

  uploadResource(data, configs) {
    return request(`${API_ROOT}/upload/file`, {
      method: "post",
      data,
      ...configs
    });
  },
  resourcesDel(id) {
    return request(`${API_ROOT}${Api.Resources}/${id}`, {
      method: "delete"
    });
  },
  videoPreview(videoId, resourceId) {
    return request(`/mgmt/resources/${videoId}/preview/${resourceId}`, {
      method: "post"
    });
  },
  videoPreviewUpdate(resourceId) {
    return request(`/mgmt/resources/${resourceId}`, {
      method: "put"
    });
  },

  devicesList(data) {
    return request(`${API_ROOT}/devices`, {
      method: "get",
      params: data
    });
  },
  devicesDel(mac) {
    return request(`${API_ROOT}/devices/${mac}`, {
      method: "delete"
    });
  },
  deviceDetailByMac(mac) {
    return request(`${API_ROOT}/devices/${mac}`, {
      method: "get"
    });
  },
  devicesByDay(data) {
    return request(`${API_ROOT}/statistics/devices/daily`, {
      method: "get",
      params: data
    });
  },
  devicesByWeek(data) {
    return request(`${API_ROOT}/statistics/devices/weekly`, {
      method: "get",
      params: data
    });
  },
  devicesByMonth(data) {
    return request(`${API_ROOT}/statistics/devices/monthly`, {
      method: "get",
      params: data
    });
  },
  devicesCountsList() {
    return request(`${API_ROOT}/devices/count/list`, {
      method: "get"
    });
  },
  devicesCounts(data) {
    return request(`${API_ROOT}/devices/count`, {
      method: "get",
      params: data
    });
  },
  devicesUpload(data) {
    return request(`${API_ROOT}/devices/import`, {
      method: "post",
      data
    });
  },
  // 1月14号新增设备分页查询列表
  // devicesPage(mac) {
  //     return request(`${API_ROOT}/devices/usage/${mac}`, {
  //       method: 'get',
  //     });
  //   },
  devicesinformation(data) {
    return request(`${API_ROOT}/devices/usage`, {
      method: "get",
      params: data
    });
  },

  devicesSearch(data) {
    return request(`${API_ROOT}/devices`, {
      method: "get",
      params: data
    });
  },

  deviceModelAdd(data) {
    return request(`${API_ROOT}/device/models`, {
      method: "post",
      data
    });
  },
  deviceModelList(data) {
    return request(`${API_ROOT}/device/models`, {
      method: "get",
      params: data
    });
  },
  deviceModelEdit(id, data) {
    return request(`${API_ROOT}/device/models/${id}`, {
      method: "put",
      data
    });
  },
  deviceModelDel(id) {
    return request(`${API_ROOT}/device/models/${id}`, {
      method: "delete"
    });
  },
  coursesAdd(data) {
    return request(`${API_ROOT}/courses`, {
      method: "post",
      data
    });
  },
  coursesList(data) {
    return request(`${API_ROOT}/courses`, {
      method: "get",
      params: data
    });
  },
  // 课程筛选
  opencoursesList(data) {
    return request(`${API_ROOT}/courses?status=0`, {
      method: "get",
      params: data
    });
  },
  coursesEdit(id, data) {
    return request(`${API_ROOT}/courses/${id}`, {
      method: "put",
      data
    });
  },
  coursesDel(id) {
    return request(`${API_ROOT}/courses/${id}`, {
      method: "delete"
    });
  },
  coursesDetailAdd(id, data) {
    return request(`${API_ROOT}/courses/${id}/detail`, {
      method: "post",
      data
    });
  },
  coursesDetail(id) {
    return request(`${API_ROOT}/courses/${id}/detail`, {
      method: "get"
    });
  },
  courseDetail(id) {
    return request(`${API_ROOT}/courses/${id}`, {
      method: "get"
    });
  },
  medalAdd(data) {
    return request(`${API_ROOT}/medals`, {
      method: "post",
      data
    });
  },
  medalList(data) {
    return request(`${API_ROOT}/medals`, {
      method: "get",
      params: data
    });
  },
  medalEdit(id, data) {
    return request(`${API_ROOT}/medals/${id}`, {
      method: "put",
      data
    });
  },
  medalDel(id) {
    return request(`${API_ROOT}/medals/${id}`, {
      method: "delete"
    });
  },
  userList(data) {
    return request(`${API_ROOT}/users`, {
      method: "get",
      params: data
    });
  },
  userAddress(id, data) {
    return request(`${API_ROOT}/users/${id}`, {
      method: "put",
      data
    });
  },
  userDel(id) {
    return request(`${API_ROOT}/users/${id}`, {
      method: "delete"
    });
  },
  userEnable(id) {
    return request(`${API_ROOT}/users/${id}/enable`, {
      method: "post"
    });
  },
  userDisable(id) {
    return request(`${API_ROOT}/users/${id}/disable`, {
      method: "post"
    });
  },
  userStatisticsByAdd(data) {
    return request(`${API_ROOT}/statistics/user/add/list`, {
      method: "get",
      params: data
    });
  },
  // 新增用户导出Excel
  userStatisticsByAddExcel(data) {
    return request(`${API_ROOT}statistics/user/add/excel`, {
      method: "get",
      params: data
    });
  },
  liucunList(data) {
    return request(`${API_ROOT}/statistics/user/retention/list`, {
      method: "get",
      params: data
    });
  },
  userOnineList(data) {
    return request(`${API_ROOT}/statistics/usr/onine/list`, {
      method: "get",
      params: data
    });
  },
  userChannelTable(data) {
    return request(`${API_ROOT}/statistics/user/channel/table`, {
      method: "get",
      params: data
    });
  },
  userChannelChart(data) {
    return request(`${API_ROOT}/statistics/user/channel/retention/chart`, {
      method: "get",
      params: data
    });
  },
  userStatisticsByActive(data) {
    return request(`${API_ROOT}/statistics/user/active/list`, {
      method: "get",
      params: data
    });
  },

  goodAdd(data) {
    return request(`${API_ROOT}/goods`, {
      method: "post",
      data
    });
  },
  goodList(data) {
    return request(`${API_ROOT}/goods`, {
      method: "get",
      params: data
    });
  },
  goodEdit(id, data) {
    return request(`${API_ROOT}/goods/${id}`, {
      method: "put",
      data
    });
  },
  goodDel(id) {
    return request(`${API_ROOT}/goods/${id}`, {
      method: "delete"
    });
  },
  goodHeats(id, heats) {
    return request(`${API_ROOT}/goods/${id}/heats/${heats}`, {
      method: "post"
    });
  },
  goodStatus(id, status) {
    return request(`${API_ROOT}/goods/${id}/status/${status}`, {
      method: "post"
    });
  },
  activityAdd(data) {
    return request(`${API_ROOT}/activity`, {
      method: "post",
      data
    });
  },
  activityList(data) {
    return request(`${API_ROOT}/activity/list`, {
      method: "get",
      params: data
    });
  },
  activityEdit(id, data) {
    return request(`${API_ROOT}/activity/${id}`, {
      method: "put",
      data
    });
  },
  activityDel(id) {
    return request(`${API_ROOT}/activity/${id}`, {
      method: "delete"
    });
  },
  activityRank(id, data) {
    return request(`${API_ROOT}/activity/${id}/rank`, {
      method: "get",
      params: data
    });
  },
  activityTop(id, value) {
    return request(`${API_ROOT}/activity/${id}/top/${value}`, {
      method: "post"
    });
  },
  activityById(id) {
    return request(`${API_ROOT}/activity/${id}`, {
      method: "get"
    });
  },
  activitySend(id, data) {
    return request(`${API_ROOT}/activity/${id}/deliver?${data}`, {
      method: "post"
    });
  },
  softwareAdd(data) {
    return request(`${API_ROOT}/update`, {
      method: "post",
      data
    });
  },
  softwareList(data) {
    return request(`${API_ROOT}/update/list`, {
      method: "get",
      params: data
    });
  },
  softwareEdit(id, data) {
    return request(`${API_ROOT}/update/${id}`, {
      method: "put",
      data
    });
  },
  softwareDel(id) {
    return request(`${API_ROOT}/update/${id}`, {
      method: "delete"
    });
  },
  updateByStatistics(id) {
    return request(`${API_ROOT}/update/${id}/statistics`, {
      method: "get"
    });
  },
  updateByDaily(id, data) {
    return request(`${API_ROOT}/update/${id}/daily`, {
      method: "get",
      params: data
    });
  },
  updateByMac(id, data) {
    return request(`${API_ROOT}/update/${id}/mac`, {
      method: "get",
      params: data
    });
  },
  updateByMacEdit(id, data) {
    return request(`${API_ROOT}/update/${id}/mac`, {
      method: "post",
      data
    });
  },
  updateByModel(id, data) {
    return request(`${API_ROOT}/update/${id}/model`, {
      method: "get",
      params: data
    });
  },
  updateByModelEdit(id, data) {
    return request(`${API_ROOT}/update/${id}/model`, {
      method: "post",
      data
    });
  },
  updateStatus(id, status) {
    return request(`${API_ROOT}/update/${id}/switch/${status}`, {
      method: "post"
    });
  },
  topicTypeAdd(data) {
    return request(`${API_ROOT}/topics/type`, {
      method: "post",
      data
    });
  },
  topicTypeList(data) {
    return request(`${API_ROOT}/topics/type`, {
      method: "get",
      params: data
    });
  },
  topicTypeEdit(id, data) {
    return request(`${API_ROOT}/topics/type/${id}`, {
      method: "put",
      data
    });
  },
  topicTypeClose(id) {
    return request(`${API_ROOT}/topics/type/${id}/close`, {
      method: "post"
    });
  },
  topicTypeOpen(id) {
    return request(`${API_ROOT}/topics/type/${id}/open`, {
      method: "post"
    });
  },
  topicTypeDel(id) {
    return request(`${API_ROOT}/topics/type/${id}`, {
      method: "delete"
    });
  },
  topicAdd(data) {
    return request(`${API_ROOT}/topics`, {
      method: "post",
      data
    });
  },
  topicList(data) {
    return request(`${API_ROOT}/topics`, {
      method: "get",
      params: data
    });
  },
  topicEdit(id, data) {
    return request(`${API_ROOT}/topics/${id}`, {
      method: "put",
      data
    });
  },
  topicClose(id) {
    return request(`${API_ROOT}/topics/${id}/close`, {
      method: "post"
    });
  },
  topicOpen(id) {
    return request(`${API_ROOT}/topics/${id}/open`, {
      method: "post"
    });
  },

  momentList(data) {
    return request(`${API_ROOT}/moments/list`, {
      method: "get",
      params: data
    });
  },
  momentById(id) {
    return request(`${API_ROOT}/moments/${id}`, {
      method: "get"
    });
  },
  momentLikeList(id) {
    return request(`${API_ROOT}/moments/${id}/likes`, {
      method: "get"
    });
  },
  momentCommentList(id) {
    return request(`${API_ROOT}/moments/${id}/comments`, {
      method: "get"
    });
  },
  momentReviewPass(id) {
    return request(`${API_ROOT}/moments/${id}/visible`, {
      method: "post"
    });
  },
  momentReviewFail(id) {
    return request(`${API_ROOT}/moments/${id}/invisible`, {
      method: "post"
    });
  },
  momentCommentReviewPass(id) {
    return request(`${API_ROOT}/moments/comment/${id}/visible`, {
      method: "post"
    });
  },
  momentCommentReviewFail(id) {
    return request(`${API_ROOT}/moments/comment/${id}/invisible`, {
      method: "post"
    });
  },
  gameAdd(data) {
    return request(`${API_ROOT}/games`, {
      method: "post",
      data
    });
  },
  gameList(data) {
    return request(`${API_ROOT}/games`, {
      method: "get",
      params: data
    });
  },
  // 11/09修改 抽奖data数据展示
  luckyList(data) {
    return request(`${API_ROOT}/lucky/page`, {
      method: "get",
      params: data
    });
  },
  luckyAdd(data) {
    return request(`${API_ROOT}/lucky/save`, {
      method: "post",
      data
    });
  },
  luckyUpdate(id, data) {
    return request(`${API_ROOT}/lucky/update`, {
      method: "put",
      data: { ...data, id }
    });
  },
  luckyimg(data) {
    return request(`${API_ROOT}/lucky/uploadimg`, {
      method: "post"
    });
  },
  luckyDel(id) {
    return request(`${API_ROOT}/lucky/${id}`, {
      method: "delete"
    });
  },
  luckyStatus(id, status) {
    return request(`${API_ROOT}/lucky/${id}/${status}`, {
      method: "put"
    });
  },

  gameEdit(id, data) {
    return request(`${API_ROOT}/games/${id}`, {
      method: "put",
      data
    });
  },
  gameDel(id) {
    return request(`${API_ROOT}/games/${id}`, {
      method: "delete"
    });
  },
  gameStatus(id, status) {
    return request(`${API_ROOT}/games/${id}/status/${status}`, {
      method: "post"
    });
  },

  configAdd(data) {
    return request(`${API_ROOT}/config`, {
      method: "post",
      data
    });
  },
  configList(data) {
    return request(`${API_ROOT}/config`, {
      method: "get",
      params: data
    });
  },
  configEdit(id, data) {
    return request(`${API_ROOT}/config/${id}`, {
      method: "put",
      data
    });
  },
  configDel(id) {
    return request(`${API_ROOT}/config/${id}`, {
      method: "delete"
    });
  },
  reportList(data) {
    return request(`${API_ROOT}/users/report`, {
      method: "get",
      params: data
    });
  },
  reportAction(data) {
    return request(`${API_ROOT}/users/report/handle`, {
      method: "post",
      data
    });
  },
  // 获取核销数据
  creditWriteOff(data) {
    return request(`${API_ROOT}/user/credit/writeoff`, {
      method: "get",
      params: data
    });
  },
  // 进行核销
  putWriteOff(data) {
    return request(`${API_ROOT}/user/credit/writeoff/${data.id}`, {
      method: "put"
    });
  },
  // 获取签到数据
  getpagedata(data) {
    return request(`${API_ROOT}/credit/page`, {
      method: "get",
      params: data
    });
  },
  // 保存签到数据
  savepagedata(data) {
    return request(`${API_ROOT}/credit/update`, {
      method: "put",
      data
    });
  },
  // 名师获取数据
  topteacherlist(data) {
    return request(`${API_ROOT}/master`, {
      method: "get",
      params: data
    });
  },
  topteacherDetail(id) {
    return request(`${API_ROOT}/master/${id}`, {
      method: "get"
    });
  },
  topteacherAdd(data) {
    return request(`${API_ROOT}/master`, {
      method: "post",
      data
    });
  },
  topteacherStatus(id, status) {
    return request(`${API_ROOT}/master/switch/${id}/${status}`, {
      method: "put"
    });
  },
  topteacherStatusEdit(id, data) {
    return request(`${API_ROOT}/master`, {
      method: "put",
      data: {
        ...data,
        id
      }
    });
  },
  topteacherDel(id) {
    return request(`${API_ROOT}/master/${id}`, {
      method: "delete"
    });
  },
  // 奥迈体
  aomaitiList(data) {
    return request(`${API_ROOT}/users?channel=3`, {
      method: "get",
      params: data
    });
  },
  aomaitiAdd(data, mobile) {
    return request(`${API_ROOT}/users/${data.mobile}`, {
      method: "post"
    });
  },
  aomaitiuserDel(id) {
    return request(`${API_ROOT}/almighty/users/${id}`, {
      method: "delete"
    });
  },
  // 领动小牛
  lingdongdevicesList(data) {
    return request(`${API_ROOT}/leadCalf/devices`, {
      method: "get",
      params: data
    });
  },
  lingdongcoursesList(data) {
    return request(`${API_ROOT}/courses?type=7`, {
      method: "get",
      params: data
    });
  },
  lingdonguserList(data) {
    return request(`${API_ROOT}/users?channel=4`, {
      method: "get",
      params: data
    });
  },
  lingdongactiveadd(data) {
    return request(`${API_ROOT}/userActiveCode/unlockLeadCalf`, {
      method: "post",
      data
    });
  },
  lingdongactiveList(data) {
    return request(`${API_ROOT}/userActiveCode`, {
      method: "get",
      params: data
    });
  },
  lingdongDel(id) {
    return request(`${API_ROOT}/userActiveCode/${id}`, {
      method: "delete"
    });
  },
  lingdongEdit(id, data) {
    return request(`${API_ROOT}/userActiveCode/${id}`, {
      method: "put",
      data
    });
  },
  // 分页获取字典分类
  getPageData(params) {
    return request(`${API_ROOT}/sys/dict`, {
      method: "get",
      params
    });
  },
  // 新增
  addDict(data) {
    return request(`${API_ROOT}/sys/dict`, {
      method: "post",
      data
    });
  },
  // 修改
  editDict(id, data) {
    return request(`${API_ROOT}/sys/dict/${id}`, {
      method: "put",
      data
    });
  },

  // 切换状态
  toggleStatus({ id, enable }) {
    return request(`${API_ROOT}/sys/dict/switch/${id}/${enable}`, {
      method: "put"
    });
  },

  // 删除
  deleteDict({ id }) {
    return request(`${API_ROOT}/sys/dict/${id}`, {
      method: "delete"
    });
  },

  // 新增二级
  addDictdetail({ label, dictId, value }) {
    return request(`${API_ROOT}/sys/dict/data`, {
      method: "post",
      data: {
        label,
        dictId,
        value
      }
    });
  },

  // 新增字典关联
  addDictRelate(data) {
    return request(`${API_ROOT}/sys/dict/data`, {
      method: "post",
      data
    });
  },

  // 某个字典的下级详情
  detailDict({ id }) {
    return request(`${API_ROOT}/sys/dict/data?dictId=${id}`, {
      method: "get"
    });
  },
  // 某个字典的下级详情
  deleteDetail({ id }) {
    return request(`${API_ROOT}/sys/dict/data/${id}`, {
      method: "delete"
    });
  },
  // 某个字典的下级详情
  editDictDetail(id, data) {
    return request(`${API_ROOT}/sys/dict/data/${id}`, {
      method: "put",
      data
    });
  },

  // 获取课程标签
  getCourseList(params) {
    return request(`/mgmt/sys/dict/tree`, {
      method: "GET",
      params
    });
  },
  // 获取名师列表
  getTeacherList(params) {
    return request(`/mgmt/master`, {
      method: "GET",
      params
    });
  },
  // 获取在线名师列表
  opengetTeacherList(params) {
    return request(`/mgmt/master?status=0`, {
      method: "GET",
      params
    });
  },
  getDictCategory(params) {
    return request(`/mgmt/sys/dict`, {
      method: "GET",
      params
    });
  },
  // 获取运动计划列表
  getExecPlanList(params) {
    return request(`/mgmt/exec/plan`, {
      method: "GET",
      params
    });
  },
  // 计划状态修改接口
  getExecPlanStatus(id, status) {
    return request(`${API_ROOT}/exec/plan/${id}/${status}`, {
      method: "put"
    });
  },
  // 计划状态
  // StatePlan(id, status) {
  //   return request(`${API_ROOT}/master/switch/${id}/${status}`, {
  //     method: "put"
  //   });
  // },
  // 添加运动计划
  addExecPlanList(data) {
    return request(`/mgmt/exec/plan`, {
      method: "POST",
      data
    });
  },
  // 删除运动计划
  delExecPlan(id) {
    return request(`/mgmt/exec/plan/${id}`, {
      method: "DELETE"
    });
  },
  // 获取运动详情
  getExecPlanDetail(id) {
    return request(`/mgmt/exec/plan/${id}`, {
      method: "GET"
    });
  },
  // // 字典详情查询
  // getDictData(dictType) {
  //   return request(`/mgmt/sys/dict/data/dictType/${dictType}`, {
  //     method: "GET"
  //   });
  // },
  // 删除商品
  delGoods(id) {
    return request(`/mgmt/goods/${id}`, {
      method: "DELETE"
    });
  },
  // 字典详情查询
  getDictDetail(dictType) {
    return request(`/mgmt/sys/dict/data/dictType/${dictType}`, {
      method: "GET"
    });
  },

  // 推送分页
  pushList(data) {
    return request(`${API_ROOT}/message`, {
      method: "get",
      params: data
    });
  },
  // 新增推送
  addPush(data) {
    return request("/mgmt/message", {
      method: "POST",
      data
    });
  },
  // 修改推送
  editPush(data) {
    return request("/mgmt/message", {
      method: "PUT",
      data
    });
  },
  // 删除推送
  delPush(id) {
    return request(`/mgmt/message/${id}`, {
      method: "DELETE"
    });
  },
  // 获取推送详情
  getPushDetail(id) {
    return request(`/mgmt/message/${id}`, {
      method: "GET"
    });
  },
  // 获取推荐位列表
  getRecomList(params) {
    return request("/mgmt/recom", {
      method: "GET",
      params
    });
  },
  // 新增推荐位
  addRecom(data) {
    return request("/mgmt/recom", {
      method: "POST",
      data
    });
  },
  // 删除推荐位
  delRecom(id) {
    return request(`/mgmt/recom/${id}`, {
      method: "DELETE"
    });
  },
  // 修改推荐位状态
  editRecom(id, status) {
    return request(`/mgmt/recom/switch/${id}/${status}`, {
      method: "PUT"
    });
  },
  // 获取推荐位详情
  getRecomDetail(id) {
    return request(`/mgmt/recom/${id}`, {
      method: "GET"
    });
  },
  // 新增用户-Excel导出
  getUserAddExcel(data) {
    return request(`${API_ROOT}/statistics/user/add/excel`, {
      method: "get",
      responseType: "blob",
      headers: {
        "content-type": "application/vnd.ms-excel;charset=UTF-8"
      },
      params: data
    });
  },
  // 活跃用户-Excel导出
  getUserActiveExcel(data) {
    return request(`${API_ROOT}/statistics/user/active/excel`, {
      method: "get",
      responseType: "blob",
      headers: {
        "content-type": "application/vnd.ms-excel;charset=UTF-8"
      },
      params: data
    });
  },
  // 渠道分析-Excel导出
  getUserChannelExcel(data) {
    return request(`${API_ROOT}/statistics/user/channel/excel`, {
      method: "get",
      responseType: "blob",
      headers: {
        "content-type": "application/vnd.ms-excel;charset=UTF-8"
      },
      params: data
    });
  },
  // 启动分析-Excel导出
  getUserOnineExcel(data) {
    return request(`${API_ROOT}/statistics/user/onine/excel`, {
      method: "get",
      responseType: "blob",
      headers: {
        "content-type": "application/vnd.ms-excel;charset=UTF-8"
      },
      params: data
    });
  },
  // 留存用户-Excel导出
  getUserRetentionExcel(data) {
    return request(`${API_ROOT}/statistics/user/retention/excel`, {
      method: "get",
      responseType: "blob",
      headers: {
        "content-type": "application/vnd.ms-excel;charset=UTF-8"
      },
      params: data
    });
  },

  //7月5号修改课程分析
  Frequencyanalysistable(data) {
    return request(`${API_ROOT}/statistics/course/times/table`, {
      method: "get",
      params: data
    });
  },
  Userattributetable(data) {
    return request(`${API_ROOT}/statistics/user/property/table`, {
      method: "get",
      params: data
    });
  },
  // gettimeframe(params) {
  //   return request(`${API_ROOT}/statistics/course/times/chart`, {
  //     method: "GET",
  //     params
  //   });
  // },

  Durationanalysis(data) {
    return request(`${API_ROOT}/statistics/course/period/table`, {
      method: "get",
      params: data
    });
  },
  EquipmentUtilization(data) {
    return request(`${API_ROOT}/statistics/device/usage/chart`, {
      method: "get",
      params: data
    });
  },
  getUserPropertyChartData(params) {
    return request(`${API_ROOT}/statistics/user/property/chart`, {
      method: "GET",
      params
    });
  },
  getCourseTimeChartData(params) {
    return request(`${API_ROOT}/statistics/course/times/chart`, {
      method: "GET",
      params
    });
  },
  getDeviceChartData(params) {
    return request(`${API_ROOT}/statistics/device/brief/chart`, {
      method: "GET",
      params
    });
  },
  getDurationChartData(params) {
    return request(`${API_ROOT}/statistics/course/period/chart`, {
      method: "GET",
      params
    });
  },
  getTimeChartData(params) {
    return request(`${API_ROOT}/statistics/course/times/chart`, {
      method: "GET",
      params
    });
  },
  getDeviceUsageChartData(params) {
    return request(`${API_ROOT}/statistics/device/usage/chart`, {
      method: "GET",
      params
    });
  },
  exportUserProperty(params) {
    return request(`${API_ROOT}/statistics/user/property/excel`, {
      method: "GET",
      params
    });
  },
  exportCourseTime(params) {
    return request(`${API_ROOT}/statistics/course/times/export`, {
      method: "GET",
      params
    });
  },
  exportTime(params) {
    return request(`${API_ROOT}/statistics/course/period/export`, {
      method: "GET",
      params
    });
  },
  exportEquipment(params) {
    return request(`${API_ROOT}/statistics/device/brief/excel`, {
      method: "GET",
      params
    });
  }
};
export default API;
