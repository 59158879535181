import React, { createElement } from 'react';
import classNames from 'classnames';
import { Button } from 'antd';
import config from './typeConfig';
import styles from './index.module.less';

//异常页面组件，包括404,501等页面的实现。
function Exception(props) {
  const {
    className,
    backText = 'back to home',
    linkElement = 'a',
    type,
    title,
    desc,
    img,
    actions,
    ...rest
  } = props;
  const pageType = type in config ? type : '404';
  const clsString = classNames(styles.exception, className);
  return (
    <div className={clsString} {...rest}>
      <div className={styles.imgBlock}>
        <div
          className={styles.imgEle}
          style={{ backgroundImage: `url(${img || config[pageType].img})` }}
        />
      </div>
      <div className={styles.content}>
        <h1>{title || config[pageType].title}</h1>
        <div className={styles.desc}>{desc || config[pageType].desc}</div>
        <div className={styles.actions}>
          {actions ||
            createElement(
              linkElement,
              {
                to: '/',
                href: '/',
              },
              <Button type="primary">{backText}</Button>,
            )}
        </div>
      </div>
    </div>
  );
}

export default Exception;
