import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './user';
import slideMenu from './slide-menu';
import networkReducer from './network';
import resources from './resources';
import role from './role';
import permission from './permission';
import menu from './menu';

const config = {
  key: 'SANSHUO',
  storage,
  whitelist: ['userReducer', 'slideMenu', 'menu'],
};
const rootReducer = persistCombineReducers(config, {
  userReducer,
  networkReducer,
  slideMenu,
  menu,
  role,
  resources,
  permission,
});

export default rootReducer;
